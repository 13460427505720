import type { PageTranslations } from "@/modules/layouts/contexts/foLayout"
import type { FunctionComponent } from "react"

import classnames from "classnames"

import { useTranslation } from "@/modules/i18n/components/useTranslation"
import { flags } from "@/modules/locales/flags"
import { useLocale } from "@/modules/locales/useLocale"
import { Link } from "@/modules/routing/components/Link"

import styles from "./LocalesDropdown.module.css"

type LocalesDropdownProps = {
  position?: "top" | "bottom"
  pageTranslations: PageTranslations
}

export const LocalesDropdown: FunctionComponent<LocalesDropdownProps> = ({ position, pageTranslations }) => {
  const { t } = useTranslation(["shared_languages"])
  const currentLocale = useLocale()

  const usedForTop = position === "top"
  const usedForBottom = position === "bottom"

  const itemFlagStyles = classnames(
    styles.item,
    styles.item__flag,
    usedForBottom && styles.item__flagOnBottom,
    usedForTop && styles.item__flagOnTop
  )

  const buttonStyles = classnames(
    styles.link,
    usedForBottom && styles.link__flagOnBottom,
    usedForTop && styles.link__flagOnTop
  )

  return (
    <ul className={styles.main}>
      <li className={itemFlagStyles}>
        <button type="button" className={buttonStyles} data-icon="expandMore|right">
          <span className={styles.linkIcon}>
            <img
              src={flags[currentLocale]?.src}
              width="18"
              height="18"
              alt="Current locale flag"
              style={{ display: "block" }}
            />
          </span>
          <span className={styles.linkLabel}>{t(`shared_languages.${currentLocale}`)}</span>
        </button>

        <ul className={styles.submenu}>
          {Object.entries(pageTranslations).map(([otherLocale, href]) => (
            <li
              data-dropdown-locale={otherLocale}
              className={classnames(styles.submenuItem, {
                [styles.submenuItem__selected]: currentLocale === otherLocale,
              })}
              key={otherLocale}
            >
              <Link href={href} className={styles.submenuLink} prefetch={false}>
                <figure className={styles.submenuLinkItem}>
                  <img
                    className={styles.imgFlag}
                    src={flags[otherLocale]?.src}
                    width="18"
                    height="18"
                    alt={`Flag for ${t(`shared_languages.${otherLocale}`)}`}
                    loading="lazy"
                  />
                  <figcaption className={styles.submenuLinkText}>{t(`shared_languages.${otherLocale}`)}</figcaption>
                </figure>
              </Link>
            </li>
          ))}
        </ul>
      </li>
    </ul>
  )
}

LocalesDropdown.defaultProps = {
  position: "top",
}
